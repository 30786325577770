import React, { Fragment, useEffect, useState } from 'react'
import './card.css'
import { calculatePercentage, createSlug, defaultSelectOtt, filterOTTPlans, filterPlans, getTotalOfAllOtt, removeFromString } from '../../api/Functions'
import { Link } from 'react-router-dom'
import { pushGTMTAG } from '../../api/GTMTag'
import PlanModal from '../../containers/Home/PlanModal'
import { getActualSubscriptionPlanBasedOnCPAddOn } from '../../api/Activity'
import OttPlatformModal from '../../containers/Home/OttPlatformModal'

const Index = ({ plan, handleClick, showSubsPopup, plans , setShowCheckoutPopup, selectPlanEvent}) => {
  const [activePlan, setIsActiveplan] = useState('Annual')
  const [filterObj, setFilterObj] = useState(plan)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [addOnsOTT, setAddOnsOTT] = useState([])
  const [planAddonList, setPlanAddonList] = useState([])
  const [popupPlan, setPopupPlan] = useState({})
  const [addOnError, setAddOnError] = useState(null)
  const [isOttPlatformModel, setIsOttPlatformModel] = useState(false)
  const [planAddons, setPlanAddons] = useState([])
  const [totalPlanAddons, setTotalsPlanAddons] = useState({ 'regional': 0, 'popular': 0 })

  const [totalOttPrice, setTotalOttPrice] = useState(getTotalOfAllOtt(plan.SubscriptionApps))
  const [monthlyDiscount, setMonthlyDiscount] = useState(0);
  var monthly_discount = {10161 : 73, 10450:76, 10448:80, 10446:83, 10096:85};
  const openModal = async (plan) => {
    setModalIsOpen(true);
    let data = await getActualSubscriptionPlanBasedOnCPAddOn({ subscriptionPlanID: plan.SubscriptionPlanID, ottSMSID: 0 });
    setAddOnsOTT(data);
    setPopupPlan(plan)

  }

  const openCloseModal = (status, plan = null) => {
    if (status) {
      setPopupPlan(plan)
    }
    setIsOttPlatformModel(status)
  }

  const handleChange = async (event, SubscriptionPlanName) => {
    const target = event.target;
    const value = target.value;
    setIsActiveplan(value);

    let temp = filterPlans(plans.Result, value, SubscriptionPlanName);
    setFilterObj(temp[0])
    var filter = temp[0];
      setFilterObj(filter)
       setPlanAddonList([])
      setPlanAddons([])
      setTotalsPlanAddons({ 'regional': 0, 'popular': 0 })
      setTotalOttPrice(getTotalOfAllOtt(temp[0].SubscriptionApps))
      if((temp[0].SubscriptionPlanDurationName).toLowerCase() == 'monthly') {
        setMonthlyDiscount(monthly_discount[temp[0].SubscriptionPlanID])
      }
    
    
  }
  const addRequireClassName = (num) => {
    if (num > 10) {
      return 'grater-10';
    } else if (num == 10) {
      return 'equal-10';
    } else if (num > 8) {
      return 'equal-10';
    } else if (num > 6) {
      return 'equal-8';
    } else {
      return 'equal-6';
    }
  }


  const closeModal = () => {

    setModalIsOpen(false)

  }



  const closePopup = async (planAddons, totalPlanAddons) => {
    var total_apps = totalPlanAddons['regional'] + totalPlanAddons['popular'];
    var is_error = false;
    if(total_apps < filterObj.SubscriptionRule.TotalMinimumAllowed) {
      setAddOnError(`(Please select mininum ${filterObj.SubscriptionRule.TotalMinimumAllowed} apps)`)
      is_error = true;
    }
    else if (filterObj.SubscriptionRule.ApplicableRegional != 0 && totalPlanAddons['regional'] > filterObj.SubscriptionRule.MaxRegional) {
      setAddOnError(`(You can add only ${filterObj.SubscriptionRule.MaxRegional} Regional App of your choice)`)
      is_error = true;

    }

    else if (filterObj.SubscriptionRule.ApplicableAddOn != 0 && totalPlanAddons['popular'] > filterObj.SubscriptionRule.MaxAddOn) {
      setAddOnError(`(You can add only ${filterObj.SubscriptionRule.MaxAddOn} Popular App of your choice)`)
      is_error = true;

    }
   
    else {

    
      if (!is_error) {

        setAddOnError(null)
        var data = await filterOTTPlans(addOnsOTT, planAddons);

        setPlanAddonList(data);

        var filter = { ...filterObj };
        filter['addonplans'] = data;
        // filter['total_ott_price'] = getTotalOfAllOtt(filterObj.SubscriptionApps, data);

        setFilterObj(filter)
        setModalIsOpen(false)
        // setTotalOttPrice(getTotalOfAllOtt(filterObj.SubscriptionApps, data))

        var ott_price = getTotalOfAllOtt(filterObj.SubscriptionApps, data);
        setTotalOttPrice(ott_price)
        setMonthlyDiscount(calculatePercentage(ott_price, filterObj.SubscriptionPriceWithTax))
      }


    }

    const getData = setTimeout(() => {
      setAddOnError(null)

    }, 3000);
  }



  return (
    <>
      <div className={`subsCard ${(filterObj.SubscriptionPlanName).toLowerCase().includes('max') ? 'best-buy-subsCard' : ""} ${removeFromString(['Flexi'], filterObj.SubscriptionPlanName).toLowerCase()}`} key={filterObj.SubscriptionPlanName}>
        {
          (filterObj.SubscriptionPlanName).toLowerCase().includes('max') ?
            <div className='bestBuy'>
              <div className="bestBuy-wrap">
                <img src={require("../../assets/images/best-buy.png")} alt="" />
                <span>Best Buy</span>
              </div>
            </div>
            : ''
        }

        <div className='cardHeader'>
          <h3 className='packageName'>
            Flexi-<span>{removeFromString(['Flexi-', 'Flexi '], filterObj.SubscriptionPlanName)}</span>
          </h3>
          <div className='packagePrice'>
            <div className='priceBox'>
              <div className='discounted'>&#8377; {filterObj.SubscriptionPriceWithTax}</div>
              {/* <div className='actual'>(&#8377; {(filterObj.SubscriptionPlanDurationName).toLowerCase() == 'annual' ? Math.ceil(filterObj.SubscriptionDisplayPriceWithTax/12)+"/M" : Math.ceil(filterObj.SubscriptionDisplayPriceWithTax/30)+"/D"})</div> */}
            </div>
            <div className='subsType'>
              <div className='radioBox'>
                <input id={filterObj.SubscriptionPlanID + "year-option"} value="Annual" defaultChecked={activePlan == 'Annual' ? true : false} name={filterObj.SubscriptionPlanID + "package-name"} type="radio" onChange={(e) => handleChange(e, filterObj.SubscriptionGroupNo)} />
                <label htmlFor={filterObj.SubscriptionPlanID + "year-option"}>Yearly</label>
                {/* <div className="check"></div> */}
              </div>
              <div className='radioBox'>
                <input id={filterObj.SubscriptionPlanID + "month-option"} value="Monthly" defaultChecked={activePlan == 'Monthly' ? true : false} name={filterObj.SubscriptionPlanID + "package-name"} type="radio" onChange={(e) => handleChange(e, filterObj.SubscriptionGroupNo)} />
                <label htmlFor={filterObj.SubscriptionPlanID + "month-option"}>Monthly</label>
                {/* <div className="check"></div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='cardBody'>
          {
            filterObj.SubscriptionApps ?
              <>
                <p>{(filterObj.SubscriptionApps.length)} Base Apps +</p>
                <h4>Any {(filterObj.SubscriptionRule.ApplicableAddOn) + (filterObj.SubscriptionRule.ApplicableRegional)} Apps</h4>

                <ul className={`platforms-list plan-ott-plateform ${filterObj.SubscriptionApps && addRequireClassName(((filterObj.SubscriptionApps).length + (filterObj.addonplans ? filterObj.addonplans.length : 0)))}`}>
                  {
                    filterObj.SubscriptionApps.map((row) => {
                      return (<li>
                        <img src={row.AppIconUrl} alt={row.AppName} /></li>
                      )
                    })
                  }
                  <li ><span className='' >+</span></li>
                  {
                    (filterObj?.addonplans)?.map((row) => {

                      return (<li>
                        {/* <span className='delet_arrow' ><a href='#!'  onClick={() => openModal(plan)} > <i className="fa fa-trash"></i> </a> </span> */}
                        <img src={row.SubscriptionApp[0]?.AppIconUrl} alt={row.SubscriptionApp[0]?.AppName} /></li>
                      )
                    })
                  }
                  {
                    (filterObj.SubscriptionRule.TotalMinimumAllowed > (filterObj.addonplans ? filterObj.addonplans.length : 0)) ?

                      <li><div className='addmore add-more-btn' onClick={() => openModal(plan)} >Add {((filterObj.SubscriptionRule.TotalAddonAllowed) )} more</div></li>
                      : ''
                  }
                </ul>
                {
                  (filterObj.SubscriptionRule.TotalMinimumAllowed > (filterObj.addonplans ? filterObj.addonplans.length : 0)) ? '' : (<div className="changeOtts" onClick={() => openModal(plan)} ><img src={require('../../assets/images/icons/reload.png')} alt="reload" /><span>Change the OTT's</span></div>)
                }

              </>
              : ""
          }
         
        </div>


         <div className='bottomWrap'>
           <div className='saveUp'>You save upto  {(filterObj.SubscriptionPlanDurationName).toLowerCase() == 'annual' ? filterObj.Discount : monthlyDiscount }%</div>
          {
            filterObj.SubscriptionRule.TotalMinimumAllowed > (filterObj.addonplans ? filterObj.addonplans.length : 0) ?

              <Link type='button' onClick={() => pushGTMTAG(filterObj, 'buy_now')} className="btn-subs inactive-subscription" >Subscribe Now</Link>
              :
              // <Link type='button' onClick={() => pushGTMTAG(filterObj, 'buy_now')} to={`/planoffer/${createSlug((removeFromString(['WATCHO'], filterObj.SubscriptionPlanName)))}`} state={filterObj} className="btn-subs active-subscription" >Subscribe Now</Link>
              // <Link type='button' onClick={() => pushGTMTAG(filterObj, 'buy_now')} to={`/planoffer/${createSlug((removeFromString(['WATCHO'], filterObj.SubscriptionPlanName)))}`} state={filterObj} className="btn-subs active-subscription" >Subscribe Now</Link>
              <button type='button' onClick={() => {pushGTMTAG(filterObj, 'buy_now');selectPlanEvent(true, filterObj)}} className="btn-subs active-subscription" >Subscribe Now</button>

              
          }

          <button onClick={() => openCloseModal(true, filterObj)} className='btn-readmore'>Know more</button>
       </div>


        <div >

          <OttPlatformModal isOttPlatformModel={isOttPlatformModel} setIsOttPlatformModel={setIsOttPlatformModel} openCloseModal={openCloseModal} popupPlan={popupPlan} planAddonList={planAddonList} ottPrice={totalOttPrice} />
        </div>
      </div>
      <PlanModal modalIsOpen={modalIsOpen} popupPlan={popupPlan}
        addOnsOTT={addOnsOTT} closeModal={closeModal} planAddonList={planAddonList} closePopup={closePopup} addOnError={addOnError} planAddons={planAddons} setPlanAddons={setPlanAddons} totalPlanAddons={totalPlanAddons} setTotalsPlanAddons={setTotalsPlanAddons} setAddOnError={setAddOnError} />


    </>
  )
}

export default Index
