import React, { useState } from 'react'
import Modal from 'react-modal'
import { filterOTTPlans } from '../../api/Functions';

Modal.setAppElement('#root');
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '31.77083vw',
        borderRadius: '1vw'
    },
};

function PlanModal({modalIsOpen, popupPlan,addOnsOTT, closeModal, planAddonList, closePopup, addOnError, planAddons, setPlanAddons, totalPlanAddons, setTotalsPlanAddons, setAddOnError}) {


    const selectPlanAddons = (e, type = null) => {
        var value = e.target.value
        var data = {...totalPlanAddons};
        
        if (e.target.checked) {
            if((data['popular']+data['regional'])+1 > popupPlan.SubscriptionRule.TotalAddonAllowed ) {
                setAddOnError(`(You can add only ${popupPlan.SubscriptionRule.TotalAddonAllowed} Apps)`)
                const getData = setTimeout(() => {
                    setAddOnError(null)
              
                  }, 3000);
            }
            else if (popupPlan.SubscriptionRule.ApplicableRegional != 0 && type == 'regional' &&  data['regional'] +1 > popupPlan.SubscriptionRule.MaxRegional) {
                setAddOnError(`(You can add only ${popupPlan.SubscriptionRule.MaxRegional} Regional App of your choice)`)
    
            }

            else if (popupPlan.SubscriptionRule.ApplicableAddOn != 0 && type == 'popular' && data['popular'] + 1 > popupPlan.SubscriptionRule.MaxAddOn) {
                setAddOnError(`(You can add only ${popupPlan.SubscriptionRule.MaxAddOn} Popular App of your choice)`)
    
            }
            else {

                setPlanAddons(prevArray => [...prevArray, value])
                if(type == 'popular') {
                    data['popular'] = data['popular']+1;
                }
                else if(type == 'regional') {
                    data['regional'] = data['regional']+1;
                }
                setTotalsPlanAddons(data)
            }
        }
        else {
            var array = [...planAddons]; // make a separate copy of the array
            var index = array.indexOf(value)
            if (index !== -1) {
                array.splice(index, 1);
                setPlanAddons(array);
            }

            var data = {...totalPlanAddons};
            if(type == 'popular') {
                data['popular'] = data['popular']-1;
            }
            else if(type == 'regional') {
                data['regional'] = data['regional']+-1;
            }
            setTotalsPlanAddons(data)
        }



    }
    return (
        <Modal
            isOpen={modalIsOpen}
            // onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Plan List"
        >
            <button onClick={closeModal} className='btn-close'><img src={require('../../assets/images/close.png')} alt='close' /></button>
            <div className='modal-content add-on-modal-content'>

                <h2 className='modal-heading'>Select Apps</h2>
                <p className='sub-desc'>{popupPlan.SubscriptionRule?.SubscriptionRuleMSG}</p>

                <div className='apps-block'>
                    {
                        popupPlan.SubscriptionRule?.ApplicableAddOn > 0 ?
                        <>
                            <p>Popular Apps</p>
                            <ul className='ott-list'>
                                {
                                    addOnsOTT.map((data) => {
                                        if (data.PackType == 'ACP') {
                                            return (
                                                <li>
                                                    <div className='platformname'>{data.SubscriptionApp[0]?.AppName}</div>
                                                    <img src={data.SubscriptionApp[0].AppIconUrl} alt={data.SubscriptionApp[0]?.AppName} />
                                                    <div className='platformprice'><input type='checkbox' checked={planAddons.includes(JSON.stringify(data.PackageID)) ? true : false} value={data.PackageID} onChange={(e) => { selectPlanAddons(e, 'popular') }} /></div>
                                                </li>
                                            )
                                        }
                                    })
                                }

                            </ul>
                        </>
                        :
                        ''
                    }
                    
                    {
                        popupPlan.SubscriptionRule?.ApplicableRegional > 0 ?
                        <>
                        <p>Regional Apps</p>
                        <ul className='ott-list'>
                            {
                                addOnsOTT.map((data) => {
                                    if (data.PackType == 'RCP') {
                                        return (
                                            <li>
                                                <div className='platformname'>{data.SubscriptionApp[0]?.AppName}</div>
                                                <img src={data.SubscriptionApp[0].AppIconUrl} alt={data.SubscriptionApp[0]?.AppName} />
                                                <div className='platformprice'><input type='checkbox' checked={planAddons.includes(JSON.stringify(data.PackageID)) ? true : false} value={data.PackageID} onChange={(e) => { selectPlanAddons(e, 'regional') }} /></div>
                                            </li>
                                        )
                                    }
                                })
                            }

                        </ul>
                        </>
                        :
                        ''
                    }
                    
                </div>
                <div className={addOnError != null ? 'fadein' : 'fadeout'}>{addOnError}</div>

                <div className='modal-footer'>
                    <button className='subsplan-btn' onClick={(e) => closePopup(planAddons, totalPlanAddons)}>Done</button>
                </div>
            </div>
        </Modal>
    )
}

export default PlanModal